


























import { Component } from 'vue-property-decorator'
import Fact from '@/components/facts/Fact.vue'
import { FactChangeEvent } from '@/interfaces/FactChangeEvent'
import { OverlayView } from '@/views/mixins/OverlayView'
import BackButton from '@/components/layout/BackButton.vue'
import NextButton from '@/components/layout/NextButton.vue'
import { ACTION_VALIDATE_PRODUCT } from '@/store/modules/product/actionTypes'
import constants from '@/constants'
import { saleJourneyActionHandler, saleJourneyRouteGuard } from '@/services/SaleJourneyService'

@Component({
  components: {
    Fact,
    BackButton,
    NextButton
  }
})
export default class Plan extends OverlayView {
  constructor () {
    super('plan')
  }

  created () {
    this.doCreated()
  }

  onFactChange (factChange: FactChangeEvent) {
    this.setFactValue(factChange)
    // clear campaign code/discount if user updates plan info
    this.setFactValue({ id: 'COMPONENT:base.deviceregistration.campaigncode', value: '' })
    this.setFactValue({ id: 'COMPONENT:base.deviceregistration.discountamount', value: '' })
    this.clearMonthlyOptionIfSelected(factChange)
  }

  get valid() {
    return this.validationErrors.length === 0
  }

  private clearMonthlyOptionIfSelected(factChange: FactChangeEvent) {
    if (factChange.value === 'true') {
      return
    }

    if (factChange.id === 'COMPONENT:base.plan.newdevice' || factChange.id === 'COMPONENT:base.plan.manufacturerwarranty') {
      this.setFactValue({ id: 'COMPONENT:base.plan.plan', value: '' })
    }
  }

  private async next() {
    await this.$store.dispatch(ACTION_VALIDATE_PRODUCT)
    if (!this.valid) {
      return
    }
    await this.$router.push({ name: constants.routeNames.APPLICATION_CERT })
  }

  mounted() {
    // Sale Journey Route Guard - to handle back button state
    saleJourneyRouteGuard(saleJourneyActionHandler(this.$store.state.product.saleJourneyState), this.$router, this.$store.state.product.invoiceId)
  }
}
